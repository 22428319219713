<template>
<div>
    <section v-if="this.$store.state.forces.length === 0">
        <NewOrderOfBattleForm />
    </section>
    <section v-if="this.$store.state.forces.length > 0">
        <p>Select a force</p>
        <select v-model="selectedForce">
            <option v-for="force in this.$store.state.forces" :key="force.name" v-bind:value="force">{{ force.name }}</option>
        </select>
    </section>
    <div class="field  is-pulled-right">
        <input id="toggleDetails" type="checkbox" name="toggleDetails" class="switch is-rtl" checked="checked" v-model="showForceDetails">
        <label for="toggleDetails">Show Details</label>
    </div>
    <div class="section">
    <div  v-if="showForceDetails">
        <div class="columns">
            <div class="column is-2">
                <h3 class="heading"><b>Crusade Force Name: </b></h3>
                <p>{{ selectedForce.name }}</p>
            </div>
            <div class="column is-2">
                <h3 class="heading" for="faction"><b>Crusade Faction: </b></h3>
                <p>{{ selectedForce.faction }}</p>
            </div>
        </div>

        <div class="columns">
            <div class="column is-2">
                <h3 class="heading"><b>Battle Tally: </b></h3>
                <p>{{ selectedForce.battleTally }}</p>
            </div>
            <div class="column is-2">
                <h3 class="heading"><b>Battles Won: </b></h3>
                <p>{{ selectedForce.battlesWon }}</p>
            </div>

            <div class="column is-2">
                <h3 class="heading"><b>Requisition Points: </b></h3>
                <p>{{ selectedForce.requisitionPoints }}</p>
            </div>
            <div class="column is-2">
                <h3 class="heading"><b>Supply Used / Limit</b></h3>
                <p>{{ selectedForce.supplyUsed }} / {{ selectedForce.supplyLimit }}</p>
            </div>
        </div>

        <div v-if="!this.$store.state.isMobile" class="columns">
            <div class="column is-6">
                <div class="heading is-size-6"><b>Crusade Cards</b></div>
            </div>

            <div class="column is-2">
                <div class="heading"><b>Power Rating</b></div>
            </div>

            <div class="column is-2">
                <div class="heading"><b>Crusade Points</b></div>
            </div>
        </div>
        <div v-if="this.$store.state.isMobile" class="columns">
            <div class="column is-6">
                <div class="heading is-size-6"><b>Crusade Cards</b></div>
            </div>
        </div>
    </div>
        
        <div>
            <div v-for="(unit, index) in selectedForce.units" :key="unit.name">
                <UnitCard class="unitCard" v-bind:index="index + 1" v-bind:unitName="unit.name" v-bind:unitPower="unit.power" v-bind:crusadePoints="unit.crusadePoints" />
            </div>
            <div class="buttons is-right">
                <button class="button is-primary">Add a Unit</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import UnitCard from "@/components/UnitCard.vue";
import NewOrderOfBattleForm from "@/components/NewOrderOfBattleForm.vue";

export default {
  name: "OrderOfBattle",
  components: {
    UnitCard,
    NewOrderOfBattleForm
  },
  data: function () {
        return {
            showForceDetails: true,
            selectedForce: this.$store.state.forces[0]
        }
    },
};
</script>

<style scoped>
.unitCard {
    margin-bottom: 10px;
}
</style>