<template>
<div>
    <div v-if="!editMode">
        <div v-if="!this.$store.state.isMobile" class="columns margin">
            <div class="column is-1 outlined">
                Unit {{ index }}:
            </div>
            <div class="column is-5 outlined">
                {{ unitName }}
            </div>
            <div class="column is-2 outlined">
                {{ unitPower }}
            </div>
            <div class="column is-2 outlined">
                {{ crusadePoints }}
            </div>
        </div>
        <div v-if="this.$store.state.isMobile" class="card">
            <header class="card-header">
                <p class="card-header-title">
                    {{ unitName }}
                </p>
            </header>
            <div class="card-content">
                <div class="content">
                    Unit Power: {{ unitPower }}
                </div>
                <div class="content">
                    Crusade Points: {{ crusadePoints }}
                </div>
            </div>
            <footer class="card-footer">
                <a v-on:click="editMode = !editMode" class="card-footer-item">Edit</a>
                <a v-on:click="deleteUnit(index)" class="card-footer-item">Delete</a>
            </footer>
        </div>
    </div>
    <div v-if="editMode">
    </div>
</div>
</template>

<script>
export default {
    props: [
        "index",
        "unitName",
        "unitPower",
        "crusadePoints"
    ],
    data: function () {
        return {
            editMode: false
        }
    },
    methods: {
        deleteUnit: function (index) {
            this.$store.commit('deleteUnit', index-1);
        }
    }
}
</script>

<style scoped>
    .outlined {
        border: 1px black solid;
    }
    .margin {
        margin-bottom: 10px;
    }
</style>