<template>
<form class="section">
    <input type="radio" id="newForce" value="true" v-model="newForce">
    <label for="newForce">New Force</label>
    <input type="radio" id="oldForce" value="false" v-model="newForce">
    <label for="oldForce">Existing Force</label>
    <div class="columns">
        <div class="column is-2">
            <label class="heading" for="forceName">Crusade Force Name:</label>
        </div>
        <div class="column is-4">
            <input class="formInput" type="text" id="forceName" name="forceName">
        </div>
    </div>

    <div class="columns">
        <div class="column is-2">
            <label class="heading" for="faction">Crusade Faction:</label>
        </div>

        <div class="column is-4">
            <select class="formInput" name="faction" id="faction">
                <option>Imperium</option>
                <option>Chaos</option>
                <option>Aeldari</option>
                <option>Tyranids</option>
                <option>Orks</option>
                <option>Necrons</option>
                <option>T'au Empire</option>
            </select>
        </div>
    </div>

    <div class="columns">
        <div class="column is-2">
            <label class="heading" for="playerName">Player Name:</label>
        </div>
        <div class="column is-4">
            <input class="formInput" type="text" id="playerName" name="playerName">
        </div>
    </div>
    <div class="columns">
        <div class="column is-2">
            <label class="heading" for="battleTally">Battle Tally: </label>
            <input type="number" id="battleTally" name="battleTally">
        </div>
        <div class="column is-2">
            <label class="heading" for="battlesWon">Battles Won: </label>
            <input type="number" id="battlesWon" name="battlesWon">
        </div>

        <div class="column is-2">
            <label class="heading" for="requisitionPoints">Requisition Points: </label>
            <input type="number" id="requisitionPoints" name="requisitionPoints">
        </div>
        <div class="column is-2">
            <label class="heading" for="supplyLimit">Supply Limit</label>
            <input type="number" id="supplyLimit" name="supplyLimit">
        </div>
        <div class="column is-2">
            <label class="heading" for="supplyUsed">Supply Used</label>
            <input id="supplyUsed" name="supplyUsed" readonly>
        </div>
    </div>

    <div v-if="!this.$store.state.isMobile" class="columns">
        <div class="column is-6">
            <div class="heading is-size-6">Crusade Cards</div>
        </div>

        <div class="column is-2">
            <div class="heading">Power Rating</div>
        </div>

        <div class="column is-2">
            <div class="heading">Crusade Points</div>
        </div>
    </div>
    <div v-if="this.$store.state.isMobile" class="columns">
        <div class="column is-6">
            <div class="heading is-size-6">Crusade Cards</div>
        </div>
    </div>
    <div>
        <div v-for="(unit, index) in this.$store.state.units" :key="unit.name">
            <UnitCard class="unitCard" v-bind:index="index + 1" v-bind:unitName="unit.name" v-bind:unitPower="unit.power" v-bind:crusadePoints="unit.crusadePoints" />
        </div>
        <div class="buttons is-right">
            <button class="button is-primary">Add a Unit</button>
        </div>
    </div>
</form>
</template>

<script>
export default {
    data: function () {
        return {
            newForce: true,
            forceName: "",
        }
    }
}
</script>

<style scoped>
.fixed {
    min-width: 160px;
    margin-right: 1vh;
}

.formHeader {
    font: bold;
    display: inline-block;
    min-width: 160px;
}

.formInputDiv {
    display: inline-block;
    margin-right: 1vh;
}

.formInput {
    width: 100%;
    min-width: 180px;
}

.formLabelSmall {
    font-size: 0.9em;
}

.unitCard {
    margin-bottom: 10px;
}
</style>